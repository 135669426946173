import React, { useCallback, useState, useRef, useEffect} from 'react';
import logo from './logo.svg';
import { createWeb3Modal, defaultSolanaConfig, useWeb3ModalAccount, useWeb3ModalProvider  } from '@web3modal/solana/react'
import { solana, solanaTestnet, solanaDevnet } from '@web3modal/solana/chains';
import { getAccount, getAssociatedTokenAddress, createTransferInstruction, createAssociatedTokenAccountInstruction} from '@solana/spl-token';
import './App.css';
import './SpacesLogo.css';
import './LoadingIcon.css'
import Template, {purseAmount, setPurseAmount, updatePurseAmount} from './Template';
import WALLET from './WALLET.png';
import USDCLOGO from './USDC.png';
import ABOUT_GAME from './ABOUT_GAME.png';
import DIFFICULTY_GAME from './DIFFICULTY_GAME.png';
import TOSS_STYLE from './TOSS_STYLE.png';
import LOADINGICON from './LoadingIcon.png';
import {
  Connection,
  clusterApiUrl,
  SystemProgram,
  PublicKey,
  Keypair,
  Transaction,
  TransactionInstruction,
  sendAndConfirmTransaction,
  LAMPORTS_PER_SOL,
  VersionedTransaction
} from '@solana/web3.js';
import base58 from 'bs58';
import { createSuperChatMessage, createUser, createUserWithdrawTransaction, getGlobalBetHistory, getLatestSuperChatMessages, getUserBetHistory, getUserPurseAmount, userBetDeposit, userBetWithdraw } from './Server/GraphqlEndpointFunctions';
import { setItemLocalStorage } from './LocalStorage';
import client, { token, setToken, wsSubscribed, setWsSubscribed } from './Server/ApolloClient';
import { SUBSCRIBE_TO_SUPER_CHAT_MESSAGES } from './Server/GraphqlEndpointConstants';


export let updateCurrentPurseAmount : (purseAmount: number) => void;
export let updateYourHistory : (yourHistory: any[]) => void;
export let GlobalYourHistoryItems:any[] = [];
export let GlobalGlobalHistoryItems:any[] = [];
export let updateGlobalHistory : (globalHistory: any[]) => void;
export let treasuryId = process.env.REACT_APP_TREASURYID!;
export let currentAddress:string | undefined;
export let globalSetDifficultyOverlay : (status: boolean) => void;
export let globalSetPlayStyleOverlay : (status: boolean) => void;
export let globalSetSuperChatItems: (superChat: any[]) => void;
export let globalSuperChatItems :any[] = [];

let USDCAddress= process.env.REACT_APP_USDCADDRESS!;

// Setup chains
let chains = [solanaDevnet];
const targetChain = process.env.REACT_APP_CHAIN!
if(targetChain == "SOLANA_DEV"){
  chains = [solanaDevnet];
}
else if(targetChain == "SOLANA"){
  chains = [solana];
}

const mainNetConnection = new Connection('https://solana-mainnet.core.chainstack.com/ec3844b97571ee7538e53f76f5b8a503');//new Connection('https://solana-mainnet.core.chainstack.com/dd78392e1c4342ba02ad77c2f5c58ea4');


//solana, solanaTestnet,

// Your WalletConnect Cloud project ID
const projectId = '2aa32bfff8b47b07e2a44bd8539f7ab6'

// Create metadata
const metadata = {
  name: 'Prediction-Market-Game',
  description: 'AppKit Example',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

// Create solanaConfig
const solanaConfig = defaultSolanaConfig({
  metadata,
  chains,
  projectId,
  auth:{
    provider:'gooogle'
  }
})

// Create modal
const web3Modal = createWeb3Modal({
  solanaConfig,
  chains,
  projectId,
  enableSwaps:true,
  // tokens: {
  //   // Add the token configuration here
  //   901: {
  //     address: '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU', // Replace with your token's address
  //     image: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png', // Optional: URL to the token image
  //   },
  // },
})

const recipientAddress = process.env.REACT_APP_TREASURYADDRESS!;


let platform = 'PC';

function getClassNamePlatform(className: string): string {
  return className.concat("-").concat(platform);
}

function convertDateToDesiredFormat(dateString: string): string{
  const date = new Date(dateString);

  // Format the date to something like "Sept 13th 19:43"
  const formattedDate = date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Change to true if you want AM/PM format
  });

  return formattedDate;
}


const Scrollbar: React.FC = () => {
  const [position, setPosition] = useState<number>(0);
  const scrollbarRef = useRef<HTMLDivElement | null>(null);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      const scrollbar = scrollbarRef.current;
      if (!scrollbar) return; // Early return if scrollbar is null

      const maxScrollWidth = scrollbar.clientWidth - 30; // 30 is the width of the thumb

      const handleMouseMove = (moveEvent: MouseEvent) => {
          let newLeft = moveEvent.clientX - scrollbar.getBoundingClientRect().left - 15; // Center the thumb
          if (newLeft < 0) newLeft = 0;
          if (newLeft > maxScrollWidth) newLeft = maxScrollWidth;

          setPosition(newLeft);
      };

      const handleMouseUp = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
  };

  // Ensure scrollbarRef.current is defined before accessing its properties
  const maxScrollWidth = scrollbarRef.current ? scrollbarRef.current.clientWidth - 30 : 0;
  const ratio = maxScrollWidth > 0 ? ((position / maxScrollWidth) * 100).toFixed(0) : '0';

  // Calculate font size based on position
  const fontSize = Math.min(25, 16 + (position / maxScrollWidth) * 8); // Increase from 14 to 22

  return (
      <div className="scroll-container" ref={scrollbarRef}>
          <div
              className="scrollbar"
              onMouseDown={handleMouseDown}
          >
              <div
                  className="scroll-thumb"
                  style={{ left: position }}
              ></div>
          </div>
          <div
              className="ratio-text"
              style={{ left: position, fontSize: `${fontSize}px` }} // Dynamic font size
          >
              ~{ratio}X
          </div>
      </div>
  );
};

const LoadingIcon: React.FC = () => {
  return (
    <div className="loading-icon">
      <div className="spinner" />
    </div>
  );
};

interface ScrollListProps {
  items: ScrollItemProp[];
}

interface ScrollItemProp {
  address: string;
  betAmount: number;
  gain: number;
  earnings: number;
  time: string
}

const ScrollList: React.FC<ScrollListProps> = ({ items }) => {
  return (
    <div className="scroll-container">
      {items.map((item, index) => (
        <div key={index} style={{display:'flex', flexDirection:"row", alignSelf:'center', height:'70px',  justifyContent:'space-between', borderRadius:'40px', borderStyle:'solid', borderColor:'rgba(0,0,0,0)', width:'100%'}}>
      
            <div className={`list-item`}>
              <span className='scroll-list-item-address-text'>{item.address}</span>
            </div>

            <div className={`list-item`}>
              <span className='scroll-list-item-text'>{item.betAmount}$</span>
            </div>

            <div className={`list-item ${item.gain>0 ? 'positive': item.gain<0 ? 'negative':''}`}>
              <span className='scroll-list-item-text'>{(item.gain * 100).toFixed(2)}{((item.gain==0)?null:"%")}</span>
            </div>

            <div className={`list-item ${item.earnings>0 ? 'positive': item.earnings<0 ? 'negative':'' }`}>
              <span className='scroll-list-item-text'>{(item.earnings>0)?'+':''}{item.earnings}$</span>
            </div>

            <div className={`list-item`}>
              <span className='scroll-list-item-text'>{convertDateToDesiredFormat(item.time)}</span>
            </div>
        </div>
      ))}
    </div>
  );
};


const YourHistory: React.FC = () => {

  const [yourHistoryItems, setYourHistoryItems] = useState(GlobalYourHistoryItems);
  updateYourHistory = setYourHistoryItems;

  useEffect(()=>{
    if(GlobalYourHistoryItems.length==0){
      setTimeout(async()=>{
      try{
        let getTossHistory = await getUserBetHistory();
        console.log("getTossHistory:"+JSON.stringify(getTossHistory));
        if(getTossHistory.status == "SUCCESS"){
          let history:ScrollItemProp[]=[];
          let localAddress;
          let localBetAmount;
          let localGain;
          let localEarnings;
          let localTime;
          
          for(let m=0; m<=getTossHistory.response.length-1; m++){
            localAddress = currentAddress!;
            localBetAmount = parseFloat(parseFloat(getTossHistory.response[m].betAmountFromPurse).toFixed(2));
            if(parseFloat(getTossHistory.response[m].winningCashOut) == 0){
              localGain = 0;
            }
            else{
              localGain =  parseFloat(((parseFloat(getTossHistory.response[m].betAmountFromPurse) + parseFloat(getTossHistory.response[m].winningCashOut))/parseFloat(getTossHistory.response[m].betAmountFromPurse)).toFixed(2))
            }
    
            if(parseFloat(getTossHistory.response[m].winningCashOut) == 0){
              localEarnings = 0;
            }
            else{
              localEarnings =  parseFloat(parseFloat(getTossHistory.response[m].winningCashOut).toFixed(2));
            }
            localTime = getTossHistory.response[m].endTimeStamp;
    
            history.push({
              address:localAddress,
              betAmount: localBetAmount,
              gain: localGain,
              earnings: localEarnings,
              time: localTime,
            });
          }
        
          setYourHistoryItems(history);
          GlobalYourHistoryItems = history;
        }
      }
      catch(e){
      }},100)
    }

    setInterval(async()=>{
      try{
        let getTossHistory = await getUserBetHistory();
        console.log("getTossHistory:"+JSON.stringify(getTossHistory));
        if(getTossHistory.status == "SUCCESS"){
          let history:ScrollItemProp[]=[];
          let localAddress;
          let localBetAmount;
          let localGain;
          let localEarnings;
          let localTime;
          
          for(let m=0; m<=getTossHistory.response.length-1; m++){
            localAddress = currentAddress!;
            localBetAmount = parseFloat(parseFloat(getTossHistory.response[m].betAmountFromPurse).toFixed(2));
            if(parseFloat(getTossHistory.response[m].winningCashOut) == 0){
              localGain = 0;
            }
            else{
              localGain =  parseFloat(((parseFloat(getTossHistory.response[m].betAmountFromPurse) + parseFloat(getTossHistory.response[m].winningCashOut))/parseFloat(getTossHistory.response[m].betAmountFromPurse)).toFixed(2))
            }
    
            if(parseFloat(getTossHistory.response[m].winningCashOut) == 0){
              localEarnings = 0;
            }
            else{
              localEarnings =  parseFloat(parseFloat(getTossHistory.response[m].winningCashOut).toFixed(2));
            }
            localTime = getTossHistory.response[m].endTimeStamp;
    
            history.push({
              address:localAddress,
              betAmount: localBetAmount,
              gain: localGain,
              earnings: localEarnings,
              time: localTime,
            });
          }
        
          setYourHistoryItems(history);
          GlobalYourHistoryItems = history;
        }
      }
      catch(e){
      }
    },5000)
  },[])

  return(
    (yourHistoryItems.length == 0)?
    <div className="loading-icon" >
      <div className="spinner" style={{display:'flex', width:'50px', height:'50px', borderWidth:'5px'}}/>
    </div>
    :
    <ScrollList items={yourHistoryItems}/>);

}

const GlobalHistory: React.FC = () => {
  const [globalHistoryItems, setGlobalHistoryItems] = useState(GlobalGlobalHistoryItems);
  updateGlobalHistory = setGlobalHistoryItems;

  useEffect(()=>{

    if(GlobalGlobalHistoryItems.length == 0){
      setTimeout(async()=>{
        try{
          let getTossHistory = await getGlobalBetHistory();
          console.log("getTossHistory:"+JSON.stringify(getTossHistory));
          if(getTossHistory.status == "SUCCESS"){
            let history:ScrollItemProp[]=[];
            let localAddress;
            let localBetAmount;
            let localGain;
            let localEarnings;
            let localTime;
            
            for(let m=0; m<=getTossHistory.response.length-1; m++){
              localAddress = getTossHistory.response[m].user.address;
              localBetAmount = parseFloat(parseFloat(getTossHistory.response[m].betAmountFromPurse).toFixed(2));
              if(parseFloat(getTossHistory.response[m].winningCashOut) == 0){
                localGain = 0;
              }
              else{
                localGain =  parseFloat(((parseFloat(getTossHistory.response[m].betAmountFromPurse) + parseFloat(getTossHistory.response[m].winningCashOut))/parseFloat(getTossHistory.response[m].betAmountFromPurse)).toFixed(2))
              }

              if(parseFloat(getTossHistory.response[m].winningCashOut) == 0){
                localEarnings = 0;
              }
              else{
                localEarnings =  parseFloat(parseFloat(getTossHistory.response[m].winningCashOut).toFixed(2));
              }
              localTime = getTossHistory.response[m].endTimeStamp;

              history.push({
                address:localAddress,
                betAmount: localBetAmount,
                gain: localGain,
                earnings: localEarnings,
                time: localTime,
              });
            }
            

            setGlobalHistoryItems(history);
            GlobalGlobalHistoryItems = history;
          }
        }catch(e){
        }
        },100);
    }

    setInterval(async()=>{
      try{
        let getTossHistory = await getGlobalBetHistory();
        console.log("getTossHistory:"+JSON.stringify(getTossHistory));
        if(getTossHistory.status == "SUCCESS"){
          let history:ScrollItemProp[]=[];
          let localAddress;
          let localBetAmount;
          let localGain;
          let localEarnings;
          let localTime;
          
          for(let m=0; m<=getTossHistory.response.length-1; m++){
            localAddress = getTossHistory.response[m].user.address;
            localBetAmount = parseFloat(parseFloat(getTossHistory.response[m].betAmountFromPurse).toFixed(2));
            if(parseFloat(getTossHistory.response[m].winningCashOut) == 0){
              localGain = 0;
            }
            else{
              localGain =  parseFloat(((parseFloat(getTossHistory.response[m].betAmountFromPurse) + parseFloat(getTossHistory.response[m].winningCashOut))/parseFloat(getTossHistory.response[m].betAmountFromPurse)).toFixed(2))
            }

            if(parseFloat(getTossHistory.response[m].winningCashOut) == 0){
              localEarnings = 0;
            }
            else{
              localEarnings =  parseFloat(parseFloat(getTossHistory.response[m].winningCashOut).toFixed(2));
            }
            localTime = getTossHistory.response[m].endTimeStamp;

            history.push({
              address:localAddress,
              betAmount: localBetAmount,
              gain: localGain,
              earnings: localEarnings,
              time: localTime,
            });
          }
          

          setGlobalHistoryItems(history);
          GlobalGlobalHistoryItems = history;
        }
      }catch(e){
      }
    },5000)
  },[])

  return(
    (globalHistoryItems.length == 0)?
    <div className="loading-icon" >
      <div className="spinner" style={{display:'flex', width:'50px', height:'50px', borderWidth:'5px'}}/>
    </div>:
    <ScrollList items={globalHistoryItems}/>);

}




function App() {

  const [inputValue, setInputValue] = useState('');
  const [active, setActive] = useState<string>('bet-mode'); // State to hold the active option
  const [depositWindowStatus, setDepositWindowStatus] = useState<Boolean>(false);
  const [withdrawWindowStatus, setWithdrawWindowStatus] = useState<Boolean>(false);
  const [difficultyOverlay, setDifficultyOverlay] = useState<Boolean>(false);
  globalSetDifficultyOverlay = setDifficultyOverlay;
  const [playStyleOverlay, setPlayStyleOverlay] = useState<Boolean>(false);
  globalSetPlayStyleOverlay = setPlayStyleOverlay;
  const [userId, setUserId] = useState<string|null>(null);
  const [currentPurseAmount, setCurrentPurseAmount] = useState(purseAmount);
  updateCurrentPurseAmount = setCurrentPurseAmount;
  
  const [aboutGameStatus, setAboutGameStatus] = useState<Boolean>(false);
  const [superChatStatus, setSuperChatStatus] = useState<Boolean>(false);
  const [histroyType, setHistoryType] = useState<string>('global-history');



  const { address, currentChain } = useWeb3ModalAccount()
  let { walletProvider, connection } = useWeb3ModalProvider()

  if(targetChain == "SOLANA"){
    connection = mainNetConnection;
  }

  const handleWalletChange = async (address: string | null | undefined) => {
    if (address) {
      console.log('Address changed or wallet connected:', address);
      
      let createdUser =  await createUser(address,false);
      console.log("createdUser:"+JSON.stringify(createdUser));
      if(createdUser.status == "SUCCESS"){
        console.log('NEW USER SET');
        setItemLocalStorage('refresh',createdUser.response.refresh);
        setItemLocalStorage('token',createdUser.response.token);
        setToken(createdUser.response.token);
        setItemLocalStorage('userId',createdUser.response.userId);
        setUserId(createdUser.response.userId);

        let userPurseResponse =  await getUserPurseAmount();
        console.log("userPurseResponse:"+JSON.stringify(userPurseResponse));
        if(userPurseResponse.status == "SUCCESS"){
          setCurrentPurseAmount(parseFloat(userPurseResponse.response.purseAmountUSDC));
          setPurseAmount(parseFloat(userPurseResponse.response.purseAmountUSDC));
        }

        if(wsSubscribed == false){
          client
            .subscribe({
              query: SUBSCRIBE_TO_SUPER_CHAT_MESSAGES,
              variables:{token:token},
              fetchPolicy:'network-only'
            })
            .subscribe({
              next(response) {
                console.log('New Super Chat Message:', response.data);
                globalSetSuperChatItems(response.data.NewSuperChatMessages)
              },
              error(err) {
                console.error('Subscription error:', err);
              },
            });

            setWsSubscribed(true);
          }

  
      }
  

    } else {
      console.log('Wallet disconnected');
      setItemLocalStorage('refresh',"");
      setItemLocalStorage('token',"");
      setToken("null");
      setItemLocalStorage('userId',"");
      setUserId(null);
      setCurrentPurseAmount(0);
      setPurseAmount(0);
    }
    
  }

  useEffect(() => {
    // Run handleWalletChange function when address or currentChain changes
    currentAddress = address;
    handleWalletChange(address);
  }, [address]);


  const Slider: React.FC = () => {

    const handleToggle = (option: string) => {
        setActive(option); // Update the active option
    };
  
    return (
        <div className="slider-container">
            <div 
                className={`option ${active === 'bet-mode' ? 'active' : ''}`} 
                onClick={() => handleToggle('bet-mode')}
            >
                bet-mode
            </div>
            <div 
                className={`option ${active === 'free-mode' ? 'active' : ''}`} 
                onClick={() => handleToggle('free-mode')}
            >
                free-mode
            </div>
            <div className={`slider ${active === 'bet-mode' ? 'slider-active' : 'slider-inactive'}`}>
                <div className="selected-text">{active}</div> {/* Display the selected option */}
            </div>
        </div>
    );
  };

  function formatToMillions(num: number): string {
    // Round the number to two decimal places if decimals are present
    num = Math.floor(num * 100) / 100;
  
    // Convert the number to a string
    const numStr = num.toString();
  
    // Split the number into the integer and decimal parts
    const [integerPart, decimalPart] = numStr.split('.');
  
    // Initialize an empty string to store the formatted integer part
    let formattedNum = '';
  
    // Iterate over the integer part from the end
    for (let i = integerPart.length - 1; i >= 0; i--) {
      // Add the current digit to the formatted number
      formattedNum = integerPart[i] + formattedNum;
  
      // Add a comma after every three digits (except the last three)
      if (i > 0 && (integerPart.length - i) % 3 === 0) {
        formattedNum = ',' + formattedNum;
      }
    }
  
    // Append the decimal part if it exists
    if (decimalPart) {
      formattedNum += '.' + decimalPart;
    }
  
    return formattedNum;
  }
  
  function convertCommaFormattedStringToNumber(input: string | number): number {
    // If the input is already a number, return it
    if (typeof input === 'number') {
      return Math.floor(input * 100) / 100;
    }
  
    // Remove commas from the string and convert it to a number
    const cleanedInput = input.replace(/[^0-9.]/g, '');
    
    // Convert the cleaned string to a number
    const result = Number(cleanedInput);

  
    // Round the number to two decimal places if decimals are present
    return Math.floor(result * 100) / 100;
  }
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      console.log('value:'+value);
      // Ensure the value is a valid number

      if (!isNaN(Number(convertCommaFormattedStringToNumber(value))) && value.trim() !== '') {
        setInputValue(formatToMillions(Number(convertCommaFormattedStringToNumber(value))));
        } else {
            setInputValue(''); // Clear the input if invalid
        }
  };

  const DepositWindow: React.FC = () => {
    const [depositAmount, setDepositAmount] = useState<number>(0);
    const [USDCWalletBalance, setUSDCWalletBalance] = useState<number>(0.0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [transactionError, setTransactionError] = useState(false);

    const handleDepositAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if(parseFloat(value) > USDCWalletBalance){
        setDepositAmount(Math.floor(USDCWalletBalance*100)/100);
      }
      else{
        setDepositAmount(Math.floor(parseFloat(value)*100)/100);
      }
    };

    const fetchUSDCBalance = useCallback(async () => {
      if (walletProvider.publicKey) {
        const usdcBalance = await fetchTokenBalance(connection, walletProvider.publicKey.toString(), USDCAddress);
        setUSDCWalletBalance((usdcBalance)/1000000);
      }
    }, [connection, walletProvider.publicKey]);

    useEffect(() => {
      fetchUSDCBalance();
    }, [fetchUSDCBalance]);

    async function transferSOL(
      connection: Connection,
      fromPubkey: string,
      toPubkey: string,
      amountSOL: number,
    ): Promise<void> {

      const amountLamports = amountSOL * 1e9;

      // Create a transaction
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: new PublicKey(fromPubkey),
          toPubkey: new PublicKey(toPubkey),
          lamports: amountLamports,
        })
      );

      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = new PublicKey(fromPubkey);

      console.log("TRANSACTION FORMED AND TRIGGERING");


      const result = await walletProvider.signAndSendTransaction(transaction);
      console.log("TRANSACTION RESULT:"+JSON.stringify(result));
    }

      async function transferToken(
        connection: Connection,
        fromPubkey: string,
        toPubkey: string,
        amount: number,
        tokenAddress: string // The SPL token address (e.g., USDC)
      ): Promise<any> {
        const fromPublicKey = new PublicKey(fromPubkey);
        const toPublicKey = new PublicKey(toPubkey);
        const tokenPublicKey = new PublicKey(tokenAddress);
      
        console.log('fromPublicKey.toString()',fromPublicKey.toString())
        console.log('toPublicKey.toString()',toPublicKey.toString())
        console.log('tokenPublicKey.toString()',tokenPublicKey.toString())

        // Get the associated token address for the sender
        const fromTokenAccount = await getAssociatedTokenAddress(tokenPublicKey, fromPublicKey);
        
        console.log('fromTokenAccount',fromTokenAccount.toString())

        // Get the associated token address for the recipient
        const toTokenAccount = await getAssociatedTokenAddress(tokenPublicKey, toPublicKey);
        console.log('toTokenAccount',toTokenAccount.toString())
      
        // Create a transaction
        const transaction = new Transaction();
      
        try {
          // Fetch the sender's token account
          const fromAccount = await getAccount(connection, fromTokenAccount);

          console.log("Sender's token balance:", fromAccount.amount.toString());
      
          // Check if the recipient's token account exists
          try {
            const toAccount = await getAccount(connection, toTokenAccount);
          } catch (error) {
            // If it doesn't exist, create it
            console.log("Recipient's token account does not exist. Creating it...");
            const createAccountInstruction = new Transaction().add(
              createAssociatedTokenAccountInstruction(
                fromPublicKey, // Payer
                toTokenAccount, // New associated token account
                toPublicKey, // Owner
                tokenPublicKey // Mint
              )
            );
            transaction.add(createAccountInstruction);
          }

      
          // Create the transfer instruction
          const transferInstruction = createTransferInstruction(
            fromTokenAccount,
            toTokenAccount,
            fromPublicKey, // The sender's public key
            amount * 10 ** 6 // Amount in the smallest unit (e.g., for USDC, which has 6 decimals)
          );
      
          // Add the transfer instruction to the transaction
          transaction.add(transferInstruction);
      
          // Get the latest blockhash and set it in the transaction
          const { blockhash } = await connection.getLatestBlockhash();
          transaction.recentBlockhash = blockhash;
          transaction.feePayer = fromPublicKey;

          const message = transaction.compileMessage();
          const fee = await connection.getFeeForMessage(message, 'processed');
      
          if (fee === null) {
            console.error("Failed to estimate transaction fee.");
            return { status: "FAILED", error: "Failed to estimate transaction fee" };
          }
      
          console.log("Estimated transaction fee:", fee);
      
          console.log("TRANSACTION FORMED AND TRIGGERING");
      
          if (!walletProvider.signAndSendTransaction) {
            console.error("Wallet provider does not support signAndSendTransaction.");
            return;
          }

          // Sign and send the transaction
          // const result = await walletProvider.signAndSendTransaction(transaction);

          const signedTransaction = await walletProvider.signTransaction(transaction);
          const result = await connection.sendRawTransaction(signedTransaction.serialize(),{
            skipPreflight: false,
            maxRetries: 3
          });
          console.log("TRANSACTION RESULT:" + result);

          return ({status:"SUCCESS", depositTransactioHash: result });
        } catch (error) {
          return ({status:"SUCCESS", error: error });
        }
      }

    async function fetchTokenBalance(connection: Connection, address: string, tokenAddress: string): Promise<number> {
      const publicKey = new PublicKey(address);
      const tokenPublicKey = new PublicKey(tokenAddress);
    
      // Get the associated token address for the wallet
      const associatedTokenAddress = await getAssociatedTokenAddress(tokenPublicKey, publicKey);
    
      try {
        const account = await getAccount(connection, associatedTokenAddress);
        return Number(account.amount); // Return the balance in the smallest unit (e.g., lamports for SOL)
      } catch (error) {
        console.error("Failed to fetch token balance:", error);
        return 0; // Return 0 if the account does not exist or an error occurs
      }
    }

    return(
      <div style={{display:'flex', position:'absolute', zIndex:4, backgroundColor:'rgba(9,12,29,0.9)',width:'100%', height:'100%', justifyContent:'center'}}>
          <div style={{display:'flex', alignSelf:'flex-start', backgroundColor:'#191a39',width:'500px', height:'270px', borderRadius:'30px', marginTop:'92px', borderStyle:'solid',borderWidth:'2px', borderColor:'#6a6aa8', flexDirection:'column',}}>
            <div style={{display:'flex', flexDirection:'row'}}>
              <button className="deposit-back-button" style={{display:'flex', alignSelf:'flex-start',width:'30px', height:'30px', marginLeft:'20px', marginTop:'20px',borderRadius:'8px',borderColor:'rgba(0,0,0,0)', justifyContent:'center'}} onClick={()=>{
                setDepositWindowStatus(false);
              }}>
                <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>{"<"}</span>
              </button>
              <div style={{display:'flex', width:'380px', marginLeft:"20px",height:'36px', backgroundColor:'#6a6aa8', borderRadius:'30px', borderWidth:'2px', marginTop:'18px', borderColor:'', justifyContent:'space-between'}}>
                <span style={{display:'flex',flex:1,fontFamily: 'Comfortaa', fontWeight: 'bold', fontSize: '12px', alignSelf:'center', color:'white', marginLeft:'10px', justifyContent:'center'}}>{address}</span>
              </div>
            </div>

            <div style={{display:'flex', marginLeft:'20px', marginTop:'15px', flexDirection:'row', alignSelf:'center'}}>
            <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>USDC wallet balance</span>
              <div style={{display:'flex', width:'180px', marginLeft:"20px",height:'50px', backgroundColor:'#090c1d', borderRadius:'30px', borderWidth:'2px', borderColor:'', justifyContent:'space-between'}}>
                <img src={USDCLOGO} style={{display:'flex',width:'32px', height:'32px', marginTop:'8px', marginLeft:'12px'}}/>
                <span style={{display:'flex',flex:1,fontFamily: 'Comfortaa', fontWeight: 'bold', fontSize: '18px', alignSelf:'center', color:'white', marginLeft:'10px'}}>{USDCWalletBalance}</span>
              </div>
            </div>

            <div style={{display:'flex', marginLeft:'20px', marginTop:'5px', flexDirection:'row', alignSelf:'center'}}>
              <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>enter deposit amount</span>
              <div className="bet-input-1">
                <img src={USDCLOGO} className="dollar-icon-1" />
                <input
                  type="number"
                  value={depositAmount}
                  onChange={handleDepositAmountChange}
                  className="input-field-1"
                />
              </div>
            </div>

            <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'10px', color:'#6a6aa8', marginTop:'6px'}}>est deposit time ~ 1 min • do not close after signing txn</span>

            <button className="deposit-button" style={{width:'50%', marginTop:'15px', alignSelf:'center', borderTopRightRadius:'22px',borderBottomRightRadius:'22px', backgroundColor:(transactionError)?"#fa4a4a":"#ffd700", color:(transactionError)?"white":"black"}} onClick={async ()=>{
                
                // await transferSOL(connection,walletProvider.publicKey,recipientAddress,amountSOL);
                
                setButtonLoading(true);
                if(transactionError == false && buttonLoading == false){
                  try{
                  let result = await transferToken(connection,walletProvider.publicKey,recipientAddress,depositAmount,USDCAddress);

                    if(result.status == "SUCCESS"){
                      console.log("treasuryId",treasuryId);
                      console.log("depositAmount.toString()",depositAmount.toString());
                      console.log("result.depositTransactioHash",result.depositTransactioHash);
                      let betDepositResult = await userBetDeposit(treasuryId,depositAmount.toString(),result.depositTransactioHash);
                      console.log('DEPOSIT RESULT: '+JSON.stringify(betDepositResult));
                      updatePurseAmount(parseFloat(betDepositResult.response.betDepositAmount));
                      updateCurrentPurseAmount(purseAmount);
                      setDepositWindowStatus(false);
                      setButtonLoading(false);
                    }
                    else{
                      setButtonLoading(false);
                      setTransactionError(true);
                      setTimeout(()=>{
                        setTransactionError(false);
                      }, 1500);
                    }
                  }
                  catch(error){
                    console.log('Error:'+error);
                    setButtonLoading(false);
                    setTransactionError(true);
                    setTimeout(()=>{
                      setTransactionError(false);
                    }, 1500);
                  }
                }

              }}>
                {(buttonLoading)?
                <LoadingIcon/>:
                (transactionError)?
                "deposit error"
                :
                "deposit"}
            </button>

          </div>
      </div>
    );
  }

  const WithdrawWindow: React.FC = () => {
    const [withdrawAmount, setWithdrawAmount] = useState<number>(0);
    const [USDCWalletBalance, setUSDCWalletBalance] = useState(0.0);
    const [buttonLoading, setButtonLoading] = useState<Boolean>(false);
    const [transactionError, setTransactionError] = useState<Boolean>(false);
    const handleWithdrawAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if(parseFloat(value) > currentPurseAmount){
        setWithdrawAmount(Math.floor(currentPurseAmount*100)/100);
      }
      else{
        setWithdrawAmount(Math.floor(parseFloat(value)*100)/100);
      }
    };

    console.log('buttonLoading',buttonLoading);

    return(
      <div style={{display:'flex', position:'absolute', zIndex:4, backgroundColor:'rgba(9,12,29,0.9)',width:'100%', height:'100%', justifyContent:'center'}}>
          <div style={{display:'flex', alignSelf:'flex-start', backgroundColor:'#191a39',width:'500px', height:'270px', borderRadius:'30px', marginTop:'92px', borderStyle:'solid',borderWidth:'2px', borderColor:'#6a6aa8', flexDirection:'column',}}>
            <div style={{display:'flex', flexDirection:'row'}}>
              <button className="deposit-back-button" style={{display:'flex', alignSelf:'flex-start',width:'30px', height:'30px', marginLeft:'20px', marginTop:'20px',borderRadius:'8px',borderColor:'rgba(0,0,0,0)', justifyContent:'center'}} onClick={()=>{
                setWithdrawWindowStatus(false);
              }}>
                <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>{"<"}</span>
              </button>
              <div style={{display:'flex', width:'380px', marginLeft:"20px",height:'36px', backgroundColor:'#6a6aa8', borderRadius:'30px', borderWidth:'2px', marginTop:'18px', borderColor:'', justifyContent:'space-between'}}>
                <span style={{display:'flex',flex:1,fontFamily: 'Comfortaa', fontWeight: 'bold', fontSize: '12px', alignSelf:'center', color:'white', marginLeft:'10px', justifyContent:'center'}}>{address}</span>
              </div>
            </div>

            <div style={{display:'flex', marginLeft:'20px', marginTop:'15px', flexDirection:'row', alignSelf:'center'}}>
            <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>purse balance</span>
              <div style={{display:'flex', width:'180px', marginLeft:"20px",height:'50px', backgroundColor:'#090c1d', borderRadius:'30px', borderWidth:'2px', borderColor:'', justifyContent:'space-between'}}>
                <img src={USDCLOGO} style={{display:'flex',width:'32px', height:'32px', marginTop:'8px', marginLeft:'12px'}}/>
                <span style={{display:'flex',flex:1,fontFamily: 'Comfortaa', fontWeight: 'bold', fontSize: '18px', alignSelf:'center', color:'white', marginLeft:'10px'}}>{currentPurseAmount.toFixed(2)}</span>
              </div>
            </div>

            <div style={{display:'flex', marginLeft:'20px', marginTop:'5px', flexDirection:'row', alignSelf:'center'}}>
              <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>enter withdraw amount</span>
              <div className="bet-input-1">
                <img src={USDCLOGO} className="dollar-icon-1" />
                <input
                  type="number"
                  value={withdrawAmount}
                  onChange={handleWithdrawAmountChange}
                  className="input-field-1"
                />
              </div>
            </div>

            <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'10px', color:'#6a6aa8', marginTop:'6px'}}>est withdraw time ~ 45 sec • do not close after signing txn</span>

            <button className="withdraw-button" style={{width:'50%', marginTop:'15px', alignSelf:'center', borderTopLeftRadius:'22px',borderBottomLeftRadius:'22px',backgroundColor:(transactionError)?"#fa4a4a":"#7741ed"}} onClick={async ()=>{
                
                if(transactionError == false && buttonLoading == false){
                  // await transferSOL(connection,walletProvider.publicKey,recipientAddress,amountSOL);
                  setButtonLoading(true);
                  try {
                  //let result = await transferTokenWithPrivateKey(recipientAddressPrivateKey,address!,withdrawAmount,USDCAddress);
                    let createUserWithdrawTransactionResult = await createUserWithdrawTransaction(withdrawAmount.toString());
                    if(createUserWithdrawTransactionResult.status == 'SUCCESS'){

                      console.log('createUserWithdrawTransactionResult.response',createUserWithdrawTransactionResult.response);
                      const valuesArray = Object.values(createUserWithdrawTransactionResult.response).map(Number);
                      const uint8Array = new Uint8Array(valuesArray);
                      console.log('uint8Array',uint8Array);
                      console.log('uint8Array.length',uint8Array.length);
                      const transaction = VersionedTransaction.deserialize(uint8Array);
                      
                      // const transaction = createUserWithdrawTransactionResult.response;
                      console.log("TRANSACTION FORMED AND TRIGGERING");
                  
                      // Sign and send the transaction
                      const signedTransaction = await walletProvider.signTransaction(transaction);
                      const fullySignedTransaction = signedTransaction.serialize();
                      const base64FullySignedTx = fullySignedTransaction.toString('base64');

                      console.log('fullySignedTransaction:',fullySignedTransaction);
                      console.log('fullySignedTransaction.length:',fullySignedTransaction.length);
                      console.log('base64FullySignedTx:', base64FullySignedTx);
                      console.log('base64FullySignedTx.length:', base64FullySignedTx.length);


                      const fullySignedTransactionValuesArray = Object.values(fullySignedTransaction).map(Number);
                      let fullySignedTransactionUint8Array = new Uint8Array(fullySignedTransactionValuesArray);
                      const fullySignedTransactionIntArray = Array.from(fullySignedTransactionUint8Array);
                      console.log('fullySignedTransaction: '+fullySignedTransaction);
                    
                      let betWithdrawResult = await userBetWithdraw(treasuryId,withdrawAmount.toString(),fullySignedTransactionIntArray);
                      if(betWithdrawResult.status == "SUCCESS"){
                        // let betWithdrawResult = await userBetWithdraw(treasuryId,withdrawAmount.toString(),result.withdrawTransactioHash);
                        console.log('WITHDRAW RESULT: '+JSON.stringify(betWithdrawResult));
                        updatePurseAmount(-1*parseFloat(betWithdrawResult.response.betWithDrawAmount));
                        updateCurrentPurseAmount(purseAmount);
                        setButtonLoading(false);
                        setWithdrawWindowStatus(false);
                      }
                      else {
                        throw new Error('Withdraw Failed');
                      }
                    }
                    else{
                      setButtonLoading(false);
                      setTransactionError(true);
                      setTimeout(()=>{
                        setTransactionError(false);
                      }, 1500);
                    }
                  }
                catch(error){
                      setButtonLoading(false);
                      setTransactionError(true);
                      setTimeout(()=>{
                        setTransactionError(false);
                      }, 1500);
                    }
                }
              }}>              
              {(buttonLoading)?
                <LoadingIcon/>
                :
                (transactionError)?
                "withdraw error"
                :
                "withdraw"}
            </button>

          </div>
      </div>
    );
  }


const AboutGame: React.FC = () => {

  return(
    <div style={{display:'flex', position:'absolute', zIndex:4, backgroundColor:'rgba(9,12,29,0.9)',width:'100%', height:'100%', justifyContent:'center'}}>
        <div style={{display:'flex', alignSelf:'flex-start', backgroundColor:'#191a39',width:'95%', height:'80%', borderRadius:'30px', marginTop:'92px', borderStyle:'solid',borderWidth:'2px', borderColor:'#6a6aa8', flexDirection:'column',}}>
          
          <div style={{display:'flex', flexDirection:'row',}}>
            <button className="deposit-back-button" style={{display:'flex', alignSelf:'flex-start',width:'30px', height:'30px', marginLeft:'20px', marginTop:'20px',borderRadius:'8px',borderColor:'rgba(0,0,0,0)', justifyContent:'center'}} onClick={()=>{
              setAboutGameStatus(false);
            }}>
              <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>{"<"}</span>
            </button>
          </div>

          <img src={ABOUT_GAME} style={{display:'flex',height:'80%',marginTop:'20px', alignSelf:'center', borderRadius:'10px'}}/>




        </div>
    </div>
  );
}

const SuperChat: React.FC = () => {

  const [superChatItems, setSuperChatItems] = useState(globalSuperChatItems);
  globalSetSuperChatItems = setSuperChatItems;
  const [depositAmount, setDepositAmount] = useState<string>("");
  const [recentlySent, setRecentlySent] = useState<Boolean>(false);
  const handleDepositAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
      setDepositAmount((value));
  };

  useEffect(()=>{
    if(globalSuperChatItems.length == 0){
    setTimeout(async()=>{
    let superChat = await getLatestSuperChatMessages();
    globalSetSuperChatItems(superChat.response)},50);
    }
  },[]);

  function maskString(input:string) {
      if (input.length <= 6) {
          return input; // If the input string is less than or equal to 4 characters, return as it is
      }
      return input.slice(0, 6) + '.'.repeat(input.length*2); // Slice the first 4 characters and replace the rest with dots
  }

  function maskMessage(input:string){
    if (input.length <= 150) {
        return input; // If the input string is less than or equal to 4 characters, return as it is
    }
    return input.slice(0, 150) + '.'.repeat(10); // Slice the first 4 characters and replace the rest with dots

  }




  console.log('superChatItems',superChatItems);

  interface Items{
    items:LocalProps[]
  }

  interface LocalProps {
    timestamp:string,
    message:string,
    userId:string,
    user:Address
  }

  interface Address{
    address:string
  }
  const ScrollList: React.FC<Items> = ({ items }) => {

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      // Scroll to the bottom of the container whenever items change
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    }, []);

    return (
      <div className="scroll-container" style={{height:'75%'}}  ref={containerRef}>
        {items.map((item, index) => (
          <div key={index} style={{display:'flex', flexDirection:"row", alignSelf:'center', height:'70px',  borderRadius:'40px', borderStyle:'solid', borderColor:'rgba(0,0,0,0)', width:'100%'}}>
        
              <div className={`list-item`} style={{width:'25%', borderTopRightRadius:'0px', borderBottomRightRadius:'0px', backgroundColor:'#7741ed', flexDirection:'column'}}>
                <span className='scroll-list-item-address-text' style={{fontSize:'10px'}}>{maskString(item.user.address.toString())}</span>
                <span className='scroll-list-item-address-text' style={{fontSize:'10px', marginTop:'4px'}}>{convertDateToDesiredFormat(item.timestamp)}</span>
              </div>
  
              <div className={`list-item`} style={{width:'75%', borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px', marginLeft:'5px', backgroundColor:'#090c1d'}}>
                <span className='scroll-list-item-address-text' style={{width:'100%', marginLeft:'20px', overflow:'hidden', textOverflow:'ellipsis'}}>{maskMessage(item.message)}</span>
              </div>


          </div>
        ))}
      </div>
    );
  };

  return(
    <div style={{display:'flex', position:'absolute', zIndex:4, backgroundColor:'rgba(9,12,29,0.9)',width:'100%', height:'100%', justifyContent:'center'}}>
        <div style={{display:'flex', alignSelf:'flex-start', backgroundColor:'#191a39',width:'95%', height:'80%', borderRadius:'30px', marginTop:'92px', borderStyle:'solid',borderWidth:'2px', borderColor:'#6a6aa8', flexDirection:'column',}}>
          
          <div style={{display:'flex', flexDirection:'row'}}>
            <button className="deposit-back-button" style={{display:'flex', alignSelf:'flex-start',width:'30px', height:'30px', marginLeft:'20px', marginTop:'20px',borderRadius:'8px',borderColor:'rgba(0,0,0,0)', justifyContent:'center'}} onClick={()=>{
              setSuperChatStatus(false);
            }}>
              <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>{"<"}</span>
            </button>
          </div>

          <ScrollList items={superChatItems}/>
         <div className="bet-input-1" style={{width:'94%', alignSelf:"flex-start", marginLeft:'40px', justifyContent:'space-between'}}>
                <input
                  type="text"
                  value={depositAmount}
                  onChange={handleDepositAmountChange}
                  className="input-field-1"
                  placeholder='type your message for global super chat'
                  style={{width:'80%', marginLeft:"20px"}}
                />
                <button className="deposit-button" style={{width:'20%', borderRadius:'30px', backgroundColor:(recentlySent)?"#191a39":"#d8b423"}} onClick={async()=>{
                  if(recentlySent == false){
                    console.log('message',depositAmount);
                    await createSuperChatMessage(depositAmount);
                    setDepositAmount("");
                    setRecentlySent(true);
                    setTimeout(()=>{
                      setRecentlySent(false);
                    },2000);
                  }
                }}>{(recentlySent)?"wait":"send"}</button>
          </div>

        </div>
    </div>
  );
}


const DifficultyOverlay: React.FC = () => {
  return(
    <div style={{display:'flex', position:'absolute', zIndex:4, backgroundColor:'rgba(9,12,29,0.9)',width:'100%', height:'100%', justifyContent:'center'}}>
        <div style={{display:'flex', alignSelf:'flex-start', backgroundColor:'#191a39',width:'95%', height:'80%', borderRadius:'30px', marginTop:'92px', borderStyle:'solid',borderWidth:'2px', borderColor:'#6a6aa8', flexDirection:'column',}}>
          
          <div style={{display:'flex', flexDirection:'row'}}>
            <button className="deposit-back-button" style={{display:'flex', alignSelf:'flex-start',width:'30px', height:'30px', marginLeft:'20px', marginTop:'20px',borderRadius:'8px',borderColor:'rgba(0,0,0,0)', justifyContent:'center'}} onClick={()=>{
              setDifficultyOverlay(false);
            }}>
              <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>{"<"}</span>
            </button>
          </div>

          <img src={DIFFICULTY_GAME} style={{display:'flex',height:'80%',marginTop:'20px', alignSelf:'center', borderRadius:'10px'}}/>

        </div>
    </div>
  );
}

const PlayStyleOverlay: React.FC = () => {
  return(
    <div style={{display:'flex', position:'absolute', zIndex:4, backgroundColor:'rgba(9,12,29,0.9)',width:'100%', height:'100%', justifyContent:'center'}}>
        <div style={{display:'flex', alignSelf:'flex-start', backgroundColor:'#191a39',width:'95%', height:'80%', borderRadius:'30px', marginTop:'92px', borderStyle:'solid',borderWidth:'2px', borderColor:'#6a6aa8', flexDirection:'column',}}>
          
          <div style={{display:'flex', flexDirection:'row'}}>
            <button className="deposit-back-button" style={{display:'flex', alignSelf:'flex-start',width:'30px', height:'30px', marginLeft:'20px', marginTop:'20px',borderRadius:'8px',borderColor:'rgba(0,0,0,0)', justifyContent:'center'}} onClick={()=>{
              setPlayStyleOverlay(false);
            }}>
              <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'20px', color:'white'}}>{"<"}</span>
            </button>
          </div>

          <img src={TOSS_STYLE} style={{display:'flex',height:'80%',marginTop:'20px', alignSelf:'center', borderRadius:'10px'}}/>

        </div>
    </div>
  );
}



// const PurseAndDeposit: React.FC = () => {

// }



  return (
    <div className={getClassNamePlatform("App")}>


      {(depositWindowStatus)?
      <DepositWindow/>
      :null}

      {(withdrawWindowStatus)?
      <WithdrawWindow/>
      :null}

      {(difficultyOverlay)?
      <DifficultyOverlay/>
      :null}

      {(playStyleOverlay)?
      <PlayStyleOverlay/>
      :null}


      {(aboutGameStatus)?
      <AboutGame/>
      :null}

      {(superChatStatus)?
      <SuperChat/>
      :null}

      <div className={getClassNamePlatform("AppHeader")}>
        <div className={getClassNamePlatform("SpacesLogoParent")}>
          <span className={getClassNamePlatform("SpacesLogoChild1")}>election.fun</span>
          <span className={getClassNamePlatform("SpacesLogoChild2")}>election.fun</span>
          <div className={getClassNamePlatform("PlayFairLogoParent")}>
            <span className={getClassNamePlatform("PlayFairLogoChild1")}>by playfair</span>
            <span className={getClassNamePlatform("PlayFairLogoChild2")}>by playfair</span>
          </div>
        </div>

        {(active == "bet-mode")?
        <div style={{display:'flex', width:'380px', height:'50px', backgroundColor:'#090c1d', borderRadius:'30px', borderWidth:'2px', borderColor:'', justifyContent:'space-between'}}>
          <img src={USDCLOGO} style={{display:'flex',width:'32px', height:'32px', marginTop:'8px', marginLeft:'12px'}}/>
          <span style={{display:'flex',flex:0.6,fontFamily: 'Comfortaa', fontWeight: 'bold', fontSize: '18px', alignSelf:'center', color:'white', marginLeft:'10px'}}>{formatToMillions(currentPurseAmount)}</span>
          <button className="deposit-button" onClick={()=>{
            setDepositWindowStatus(true);
          }}>deposit</button>
          <button className="withdraw-button" onClick={()=>{
            setWithdrawWindowStatus(true);
          }}>withdraw</button>
        </div>:
        <div style={{display:'flex', width:'180px', marginLeft:"180px",height:'50px', backgroundColor:'#090c1d', borderRadius:'30px', borderWidth:'2px', borderColor:'', justifyContent:'space-between'}}>
          <img src={USDCLOGO} style={{display:'flex',width:'32px', height:'32px', marginTop:'8px', marginLeft:'12px'}}/>
          <span style={{display:'flex',flex:1,fontFamily: 'Comfortaa', fontWeight: 'bold', fontSize: '18px', alignSelf:'center', color:'white', marginLeft:'10px'}}>{formatToMillions(currentPurseAmount)}</span>
        </div>
        }

        {/* <Slider/> */}
        <button className="game-info-button" onClick={()=>{
          setAboutGameStatus(true);
        }}>
          <span className='game-info-button-text '>i</span>
        </button>

        <button className="super-chat-button" onClick={()=>{
          setSuperChatStatus(true);
        }}>
          <span className='super-chat-button-text '>super chat</span>
        </button>

        <div style={{marginTop:'4px'}}>
          <w3m-button/> 
        </div>
      </div>
      
      <div className={getClassNamePlatform("Game")}>
        <Template/>
      </div> 

      <div className={getClassNamePlatform("Toss-History")}>
        
        <div style={{display:'flex', marginTop:'20px', width:'95%', borderRadius:'30px', height:'70px', alignSelf:'center', flexDirection:'row', justifyContent:'space-between'}}>
          <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', color:'white', fontSize:'17px', marginLeft:'20px', marginBottom:'10px'}}>recent results</span>

          <div style={{display:'flex', flexDirection:"row", alignSelf:'center', marginRight:'10px', backgroundColor:'#191a39', height:'70px', paddingLeft:'15px', paddingRight:'15px', justifyContent:'center', borderRadius:'40px', borderStyle:'solid', borderColor:'rgba(0,0,0,0)'}}>
            <button className={`your-history-button ${histroyType === 'your-history' ? 'active' : ''}`}  style={{marginRight:"10px"}} onClick={async ()=>{
              setHistoryType('your-history');
            }}>
              <span className='your-history-button-text'>your history</span>
            </button>

            <button className={`global-button ${histroyType === 'global-history' ? 'active' : ''}`} style={{marginLeft:"10px"}} onClick={async()=>{
              setHistoryType('global-history');
            }}>
              <span className='global-button-text'>global</span>
            </button>
          </div>
        </div>

        <div style={{display:'flex', flexDirection:"row", alignSelf:'center', marginRight:'10px', backgroundColor:'#191a39', height:'76px', paddingLeft:'15px', paddingRight:'15px', justifyContent:'space-between', borderRadius:'40px', borderStyle:'solid', borderColor:'rgba(0,0,0,0)', width:'90%', marginTop:'20px'}}>
            
            <div className={`list-title`}>
              <span className='your-history-button-text'>address</span>
            </div>

            <button className={`list-title`} onClick={()=>{
              setHistoryType('your-history');
            }}>
              <span className='your-history-button-text'>bet amount</span>
            </button>

            <button className={`list-title`} onClick={()=>{
              setHistoryType('your-history');
            }}>
              <span className='your-history-button-text'>gain</span>
            </button>

            <button className={`list-title`} onClick={()=>{
              setHistoryType('your-history');
            }}>
              <span className='your-history-button-text'>earnings</span>
            </button>

            <button className={`list-title`} onClick={()=>{
              setHistoryType('global-history');
            }}>
              <span className='global-button-text'>time</span>
            </button>
        </div>
        
        {(histroyType=='your-history')?
        <YourHistory/>:
        <GlobalHistory/>
        }

      </div> 
        

    </div>
  );


}

export default App;
